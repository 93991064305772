import { FC } from 'react';
import { EditActionButtonsProps } from 'features/carrierCapacity/CapacityTypes';
import { Button } from 'shamrock-clover-ui';

export const ActionButtons: FC<EditActionButtonsProps> = ({
  disable,
  handleOnCancel,
}) => (
  <div style={{ display: 'flex', gap: 20 }}>
    <Button buttonStyle="outlined" onClick={handleOnCancel}>
      Cancel
    </Button>
    <Button buttonStyle="solid" color="green" disabled={disable}>
      Add Lane
    </Button>
  </div>
);

export const EditActionButtons: FC<EditActionButtonsProps> = ({
  disable,
  handleOnShowDialog,
  handleOnCancel,
}) => {
  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <Button buttonStyle="outlined" onClick={handleOnCancel}>
        Cancel
      </Button>
      <Button buttonStyle="outlined" onClick={handleOnShowDialog}>
        Delete lane
      </Button>
      <Button buttonStyle="solid" color="green" disabled={disable}>
        Save Changes
      </Button>
    </div>
  );
};
