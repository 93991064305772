import { isEqual } from 'lodash';
import {
  createContext,
  FC,
  FormEvent,
  ChangeEvent as IChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChangeEvent } from 'react-autosuggest';
import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import { useSearchCapacityQueryParams } from '../../../hooks/useQueryParams';
import {
  EquipmentType,
  ICarrierCapacityContext,
  LaneAndCapacityForm,
  LaneObj,
  LanesAndCapacity,
  LoadDetailsType,
  resultsState,
} from '../CapacityTypes';
import { City } from '../components/SearchLocationsInputs';
import { fakeAPIResponse } from '../fakeApiResponse';
import {
  additionalServicesOptions,
  capitalizeWords,
  parseRateAmount,
  trailers,
} from '../utils/capacityUtils';

export const CarrierCapacityContext = createContext<ICarrierCapacityContext>(
  {} as ICarrierCapacityContext,
);

const CarrierCapacityWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  // initial State for table
  const [lanesAndCapacity, setLanesAndCapacity] = useState<LanesAndCapacity[]>(
    [],
  );

  // change this for the correct carriers type, for now is using the same fake data as lanes capacity
  const [carriers, setCarriers] = useState<LanesAndCapacity[]>([]);

  const [showDialog, setShowDialog] = useState(false);

  const editFormCopy = useRef<LaneAndCapacityForm | null>(null);
  const rateAmountRef = useRef<boolean>(false);

  // handle Drawer States
  const [openDetails, setOpenDetails] = useState(false);
  const [openAddLane, setOpenAddLane] = useState(false);
  const [openEditLane, setOpenEditLane] = useState(false);
  const [addCapacity, setAddCapacity] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [laneHasCapacity, setLaneHasCapacity] = useState(false);
  const [deletingCapacity, setDeletingCapacity] = useState(false);

  const [inputsError, setInputsError] = useState<{ [key: string]: boolean }>(
    {},
  );

  const [laneAndCapacityForm, setLaneAndCapacityForm] =
    useState<LaneAndCapacityForm>({} as LaneAndCapacityForm);

  const [resultsState, setResultsState] = useState<resultsState>('initialView');

  const { setQueryParams, queryParams, clearQueryParams } =
    useSearchCapacityQueryParams();

  const searchLanesAndCapacity = async (): Promise<void> => {
    //search the api
    const apiResults = fakeAPIResponse;
    setLanesAndCapacity(apiResults);
    setResultsState('resultsAvailable');
  };

  const searchCarriers = async (value: string): Promise<void> => {
    //search the api
    const apiResults = fakeAPIResponse;
    setCarriers(apiResults);
    setResultsState('resultsAvailable');
  };

  const cleanLanesAndCapacityState = () => {
    setLanesAndCapacity([]);
    setResultsState('initialView');
  };

  const resetSearchBar = () => {
    clearQueryParams();
    setResultsState('initialView');
  };

  //Pickup and delivery option handler
  const handleOptionSelected = (
    name: string,
    required: boolean,
    option: MenuOption,
  ) => {
    if (option.optionName === '') return;

    if (name === 'pickup') {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
        locationPickup: { required: true, optionSelected: { optionName: '' } },
      });
    } else if (name === 'delivery') {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
        locationDelivery: {
          required: true,
          optionSelected: { optionName: '' },
        },
      });
    } else {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
      });
    }
  };

  // Init Pickup and Delivery locations handlers
  const handleSuggestLocation = (
    e: FormEvent<HTMLElement>,
    { newValue }: ChangeEvent,
  ): void => {
    const target = e.target as HTMLInputElement;
    if (!target.name) return;

    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      [target.name]: {
        ...laneAndCapacityForm[target.name as keyof LaneAndCapacityForm],
        optionSelected: { optionName: newValue },
      },
    });
  };

  const handleSuggestionSelected = (
    _: SyntheticEvent,
    { suggestion }: { suggestion: City },
    name: string,
  ) => {
    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      [name]: {
        ...laneAndCapacityForm[name as keyof LaneAndCapacityForm],
        optionSelected: { optionName: suggestion.description },
      },
    });
  };

  // MultiSelect Trialer handler
  const handleSelectedTrailers = (
    optionText: string,
    result: 'added' | 'removed',
  ) => {
    const updatedOptions = laneAndCapacityForm.trailers.multiSelect?.map(
      (option) => {
        if (option.text === optionText) {
          option.checked = result === 'added';
        }
        return option;
      },
    );

    const findIfNoOneSelected = updatedOptions?.filter((x) => x.checked);
    const findTrailerTypeSelected = updatedOptions?.filter(
      (x) =>
        x.checked &&
        x.text === laneAndCapacityForm.trailerType?.optionSelected?.optionName,
    );

    if (
      (addCapacity && findIfNoOneSelected?.length === 0) ||
      (addCapacity && findTrailerTypeSelected?.length === 0)
    ) {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        trailers: {
          ...laneAndCapacityForm.trailers,
          multiSelect: updatedOptions,
        },
        trailerType: {
          required: true,
          optionSelected: { optionName: '' },
        },
      });
      if (addCapacity) {
        setInputsError({
          ...inputsError,
          trailerType: true,
        });
      }
    } else {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        trailers: {
          ...laneAndCapacityForm.trailers,
          multiSelect: updatedOptions,
        },
      });
    }
  };

  //Drawers Handlers
  // - Add Lane
  const handleAddLaneDrawer = () => {
    setOpenDetails(false);
    setOpenAddLane(true);
    // Add lane Form Init
    setLaneAndCapacityForm({
      pickup: {
        optionSelected: { option: 'city', optionName: 'City or Zip' },
        required: true,
      },
      delivery: {
        optionSelected: { option: 'city', optionName: 'City or Zip' },
        required: true,
      },
      locationPickup: {
        optionSelected: { optionName: '' },
        required: true,
      },
      locationDelivery: {
        optionSelected: { optionName: '' },
        required: true,
      },
      trailers: {
        required: true,
        multiSelect: trailers,
      },
    });
  };

  // - Edit Lane Drawer
  const handleEditLaneDrawer = (dataToEdit: LaneObj) => {
    const {
      pickupType,
      pickupCity,
      pickupState,
      deliveryType,
      deliveryCity,
      deliveryState,
      trailerType,
      capacity,
    } = dataToEdit;

    setOpenDetails(false);
    setOpenEditLane(true);

    //Add info for existing lane to Form
    if (capacity) {
      setAddCapacity(true);
      setLaneHasCapacity(true);
      const {
        trucks,
        loadsPerTimeFrame,
        frequencyTimeFrame,
        loadDetailNotes,
        rate,
        loadDetailServices,
      } = capacity;
      const { amount, rateType, trailerType } = rate[0];
      setIsEdited(true);
      setLaneAndCapacityForm({
        pickup: {
          optionSelected: {
            option: pickupType === 'City' ? 'city' : 'state',
            optionName: pickupType === 'City' ? 'City or Zip' : 'State',
          },
          required: true,
        },
        delivery: {
          optionSelected: {
            option: deliveryType === 'City' ? 'city' : 'state',
            optionName: deliveryType === 'City' ? 'City or Zip' : 'State',
          },
          required: true,
        },
        locationPickup: {
          optionSelected: {
            optionName:
              pickupType === 'City' ? pickupCity?.cityName! : pickupState,
          },
          required: true,
        },
        locationDelivery: {
          optionSelected: {
            optionName:
              deliveryType === 'City' ? deliveryCity?.cityName! : deliveryState,
          },
          required: true,
        },
        trailers: {
          required: true,
          multiSelect: trailers.map((x) => {
            if (trailerType.includes(x.text as EquipmentType)) {
              return { ...x, checked: true };
            }
            return x;
          }),
        },
        truckQuantity: {
          optionSelected: { optionName: trucks.toString() },
          required: true,
        },
        loadsPerTimeFrame: {
          optionSelected: { optionName: loadsPerTimeFrame.toString() },
          required: true,
        },
        timeFrame: {
          optionSelected: { optionName: frequencyTimeFrame },
          required: true,
        },
        trailerType: {
          optionSelected: { optionName: trailerType },
          required: true,
        },
        rateAmount: {
          optionSelected: {
            optionName:
              rateType === 'Flat rate'
                ? `$${parseRateAmount(amount.toString(), 'Flat rate')}`
                : `$${parseRateAmount(amount.toString(), 'Per mile')}`,
          },
          required: false,
        },
        selectedRateType: {
          optionSelected: { optionName: rateType },
          required: false,
        },
        additionalServices: {
          multiSelect: additionalServicesOptions.map((x) => {
            const parseText = loadDetailServices.map((capitalize) =>
              capitalizeWords(capitalize),
            );
            if (parseText.includes(x.text as LoadDetailsType)) {
              return { ...x, checked: true };
            }
            return x;
          }),
          required: false,
        },
        commentValue: {
          optionSelected: { optionName: loadDetailNotes },
          required: false,
        },
      });
    } else {
      setIsEdited(true);
      setLaneAndCapacityForm({
        pickup: {
          optionSelected: {
            option: pickupType === 'City' ? 'city' : 'state',
            optionName: pickupType === 'City' ? 'City or Zip' : 'State',
          },
          required: true,
        },
        delivery: {
          optionSelected: {
            option: deliveryType === 'City' ? 'city' : 'state',
            optionName: deliveryType === 'City' ? 'City or Zip' : 'State',
          },
          required: true,
        },
        locationPickup: {
          optionSelected: {
            optionName:
              pickupType === 'City' ? pickupCity?.cityName! : pickupState,
          },
          required: true,
        },
        locationDelivery: {
          optionSelected: {
            optionName:
              deliveryType === 'City' ? deliveryCity?.cityName! : deliveryState,
          },
          required: true,
        },
        trailers: {
          required: true,
          multiSelect: trailers.map((x) => {
            if (trailerType.includes(x.text as EquipmentType)) {
              return { ...x, checked: true };
            }
            return x;
          }),
        },
      });
    }
  };

  const handleOnCloseAddLane = () => {
    setOpenAddLane(false);
    setAddCapacity(false);

    //Reset the form if Drawer is closed
    laneAndCapacityForm.trailers.multiSelect?.forEach(
      (x) => (x.checked = false),
    );
    if (addCapacity) {
      laneAndCapacityForm.additionalServices?.multiSelect?.forEach(
        (x) => (x.checked = false),
      );
    }
    setInputsError({});
    setLaneAndCapacityForm({} as LaneAndCapacityForm);
  };

  const handleOnCloseEditLane = () => {
    // if the delete dialog is open, we don't want to close the drawer
    if (showDialog) {
      return;
    }
    setOpenEditLane(false);
    setAddCapacity(false);
    setIsEdited(false);
    setLaneHasCapacity(false);
    //Reset the form if Drawer is closed
    laneAndCapacityForm.trailers.multiSelect?.forEach(
      (x) => (x.checked = false),
    );

    editFormCopy.current = null;
    rateAmountRef.current = false;
    setInputsError({});
    setLaneAndCapacityForm({} as LaneAndCapacityForm);
    // When the user closes the edit lane drawer, we want to reset the selected lane to discard changes
  };

  //Capacity from handlers
  //  Init Capacity inputs
  const handleAddCapacity = () => {
    setAddCapacity(true);
    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      truckQuantity: {
        optionSelected: { optionName: '' },
        required: true,
      },
      loadsPerTimeFrame: {
        optionSelected: { optionName: '' },
        required: true,
      },
      timeFrame: {
        optionSelected: { optionName: '' },
        required: true,
      },
      trailerType: {
        optionSelected: { optionName: '' },
        required: true,
      },
      rateAmount: {
        optionSelected: { optionName: '' },
        required: false,
      },
      selectedRateType: {
        optionSelected: { optionName: 'Flat rate' },
        required: false,
      },
      additionalServices: {
        multiSelect: additionalServicesOptions,
        required: false,
      },
      commentValue: {
        optionSelected: { optionName: '' },
        required: false,
      },
    });
  };

  const handleCapacityInputsOnBlur = (e: IChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'truckQuantity') {
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          truckQuantity: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          truckQuantity: false,
        });
      }
    }

    if (e.target.name === 'loadsPerTimeFrame') {
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: false,
        });
      }
    }
  };

  const handleCapacityInputsOnChange = (e: IChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'truckQuantity') {
      let value = e.currentTarget.value.replace(/^0+/g, '');
      value = value.replace(/[^0-9]/g, '').slice(0, 4);
      if (value.length === 4) {
        value = value.replace(/^(\d)(\d{3})$/, '$1,$2');
      }
      e.currentTarget.value = value;
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        truckQuantity: {
          required: true,
          optionSelected: { optionName: e.target.value },
        },
      });
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          truckQuantity: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          truckQuantity: false,
        });
      }
    }

    if (e.target.name === 'loadsPerTimeFrame') {
      e.currentTarget.value = e.currentTarget.value.replace(/^0+/g, '');
      e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');

      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        loadsPerTimeFrame: {
          required: true,
          optionSelected: { optionName: e.currentTarget.value },
        },
      });
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: false,
        });
      }
    }
  };

  const handleRateAmount = (e: IChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      rateAmountRef.current = true;
    }
    if (
      laneAndCapacityForm.selectedRateType?.optionSelected?.optionName ===
      'Flat rate'
    ) {
      const value = parseRateAmount(e.target.value, 'Flat rate');

      if (value === '') {
        rateAmountRef.current = false;
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: '' },
          },
        });
      } else {
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: `$${value}` },
          },
        });
      }
    }

    if (
      laneAndCapacityForm.selectedRateType?.optionSelected?.optionName ===
      'Per mile'
    ) {
      const value = parseRateAmount(e.target.value, 'Per mile');
      if (value === '') {
        rateAmountRef.current = false;
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: '' },
          },
        });
      } else {
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: `$${value}` },
          },
        });
      }
    }
  };

  const handleCapacityOptionsSelected = (
    name: string,
    required: boolean,
    option: MenuOption,
  ) => {
    if (option.optionName === '') return;

    if (option.optionName === 'Flat rate' || option.optionName === 'Per mile') {
      if (
        isEdited &&
        laneAndCapacityForm.rateAmount?.optionSelected?.optionName !== ''
      ) {
        rateAmountRef.current = true;
      }
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
        rateAmount: {
          optionSelected: { optionName: '' },
          required: false,
        },
      });
    } else {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
      });
    }

    if (name === 'timeFrame' && option.optionName === '') {
      setInputsError({
        ...inputsError,
        timeFrame: true,
      });
    } else {
      setInputsError({
        ...inputsError,
        timeFrame: false,
      });
    }
    if (name === 'trailerType' && option.optionName === '') {
      setInputsError({
        ...inputsError,
        trailerType: true,
      });
    } else {
      setInputsError({
        ...inputsError,
        trailerType: false,
      });
    }
  };

  const handleAdditionalServices = (
    optionText: string,
    result: 'added' | 'removed',
  ) => {
    const updatedOptions =
      laneAndCapacityForm.additionalServices?.multiSelect?.map((option) => {
        if (option.text === optionText) {
          option.checked = result === 'added';
        }
        return option;
      });

    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      additionalServices: {
        multiSelect: updatedOptions,
        required: false,
      },
    });
  };

  const handleComments = (value: string) => {
    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      commentValue: {
        optionSelected: { optionName: value },
        required: false,
      },
    });
  };

  const handleDeleteCapacity = () => {
    if (laneHasCapacity) {
      setDeletingCapacity(true);
      setShowDialog(true);
      return;
    }

    laneAndCapacityForm.additionalServices?.multiSelect?.forEach(
      (x) => (x.checked = false),
    );
    delete laneAndCapacityForm.truckQuantity;
    delete laneAndCapacityForm.loadsPerTimeFrame;
    delete laneAndCapacityForm.timeFrame;
    delete laneAndCapacityForm.trailerType;
    delete laneAndCapacityForm.rateAmount;
    delete laneAndCapacityForm.selectedRateType;
    delete laneAndCapacityForm.additionalServices;
    delete laneAndCapacityForm.commentValue;

    rateAmountRef.current = false;
    setLaneAndCapacityForm({ ...laneAndCapacityForm });
    setInputsError({});
    setAddCapacity(false);
  };

  useEffect(() => {
    const disableForm = (form: LaneAndCapacityForm): boolean => {
      let disable: boolean = false;
      for (const key in form) {
        const field = form[key as keyof LaneAndCapacityForm];
        if (field?.required) {
          if (field.optionSelected && field.optionSelected.optionName === '') {
            disable = true;
            break;
          }

          if (field?.multiSelect) {
            const hasChecked = field.multiSelect.some((x) => x.checked);
            if (!hasChecked) {
              disable = true;
              break;
            }
          }
        }
      }
      return disable;
    };

    if (isEdited && !editFormCopy.current) {
      console.log('copy the form');
      editFormCopy.current = JSON.parse(JSON.stringify(laneAndCapacityForm));
    }

    const isFormEdited = (form: LaneAndCapacityForm): boolean => {
      if (!editFormCopy.current) return false;
      return isEqual(form, editFormCopy.current);
    };

    if (isEdited) {
      console.log('laneAndCapacityForm :>> ', laneAndCapacityForm);
      console.log('editFormCopy :>> ', editFormCopy.current);
      console.log('isFormEdited() :>> ', isFormEdited(laneAndCapacityForm));
    }

    setDisable(
      disableForm(laneAndCapacityForm) ||
        (isEdited && isFormEdited(laneAndCapacityForm)),
    );
    // eslint-disable-next-line
  }, [laneAndCapacityForm]);

  //Dialog Handlers

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setDeletingCapacity(false);
  };

  const handleOnCancelDrawer = () => {
    setOpenAddLane(false);
    setOpenEditLane(false);
    setAddCapacity(false);
    setIsEdited(false);
    //Reset the form if Drawer is closed
    laneAndCapacityForm.trailers.multiSelect?.forEach(
      (x) => (x.checked = false),
    );

    editFormCopy.current = null;
    rateAmountRef.current = false;
    setInputsError({});
    setLaneAndCapacityForm({} as LaneAndCapacityForm);
    setOpenDetails(true);
  };

  return (
    <CarrierCapacityContext.Provider
      value={{
        lanesAndCapacity,
        carriers,
        queryParams,
        resultsState,
        laneAndCapacityForm,
        openDetails,
        openAddLane,
        openEditLane,
        addCapacity,
        disable,
        showDialog,
        isEdited,
        laneHasCapacity,
        deletingCapacity,
        inputsError,
        rateAmountRef,
        searchLanesAndCapacity,
        cleanLanesAndCapacityState,
        searchCarriers,
        setOpenDetails,
        setQueryParams,
        resetSearchBar,
        handleAddLaneDrawer,
        handleEditLaneDrawer,
        handleOnCloseAddLane,
        handleOnCloseEditLane,
        handleShowDialog,
        handleCloseDialog,
        handleOnCancelDrawer,
        handleOptionSelected,
        handleCapacityOptionsSelected,
        handleSuggestLocation,
        handleSuggestionSelected,
        handleSelectedTrailers,
        handleAddCapacity,
        handleDeleteCapacity,
        handleCapacityInputsOnChange,
        handleCapacityInputsOnBlur,
        handleAdditionalServices,
        handleRateAmount,
        handleComments,
      }}
    >
      {children}
    </CarrierCapacityContext.Provider>
  );
};

export default CarrierCapacityWrapper;
