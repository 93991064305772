import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import states from '../../../utils/locations/usStates.json';

export const additionalServicesOptions = [
  { text: 'Blanket Wrap', checked: false },
  { text: 'Drop Trailer', checked: false },
  { text: 'Hazmat', checked: false },
  { text: 'Tarps', checked: false },
  { text: 'White Glove', checked: false },
];

export const trailers = [
  { text: 'Van', checked: false },
  { text: 'Straight Box Truck', checked: false },
  { text: 'Reefer', checked: false },
  { text: 'Flatbed', checked: false },
  { text: 'Flatbed Hotshot', checked: false },
  { text: 'Step Deck', checked: false },
  { text: 'Power Only', checked: false },
  { text: 'Specialized', checked: false },
];

export const frequencyOptions = [
  { optionName: 'Day' },
  { optionName: 'Week' },
  { optionName: 'Month' },
  { optionName: 'Year' },
];

export const rateTypeOptions = [
  { optionName: 'Flat rate' },
  { optionName: 'Per mile' },
];

export const capitalizeWords = (word: string): string => {
  return word
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const buildStatesObj: MenuOption[] = states.map((x) => {
  return {
    optionName: x.name,
  };
});

export const parseRateAmount = (value: string, rateType: string): string => {
  let val = value.replace(/^0*/g, '');
  val = val.replace(/[^0-9]/g, '');
  if (rateType === 'Flat rate') {
    val = val.slice(0, 5);
    if (val.length === 4) {
      val = val.replace(/^(\d)(\d{3})$/, '$1,$2');
    } else if (val.length === 5) {
      val = val.replace(/^(\d{2})(\d{3})$/, '$1,$2');
    }
  } else if (rateType === 'Per mile') {
    val = val.slice(0, 3);
    if (val.length > 1) {
      val = val.slice(0, 1) + '.' + val.slice(1);
    }
  }
  return val;
};
