import { useContext, useEffect } from 'react';
import {
  Container,
  CapacitySearchContainer,
  ViewText,
  TextContainer,
} from './CarrierCapacityStyles';
import SearchCapacityToolbar from './components/SearchCapacityToolbar';
import SearchCapacityResults from './components/SearchCapacityResults';
import { CarrierCapacityContext } from './context/CarrierCapacityContext';

const SearchCapacity = () => {
  const { resultsState, lanesAndCapacity, cleanLanesAndCapacityState } =
    useContext(CarrierCapacityContext);

  useEffect(() => {
    if (lanesAndCapacity.length === 0) {
      cleanLanesAndCapacityState();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <CapacitySearchContainer>
        <SearchCapacityToolbar />
        {resultsState === 'initialView' ? (
          <>
            <TextContainer>
              <ViewText>
                Add details to find carriers with matching lanes and capacity
              </ViewText>
            </TextContainer>
          </>
        ) : (
          <SearchCapacityResults />
        )}
      </CapacitySearchContainer>
    </Container>
  );
};

export default SearchCapacity;
