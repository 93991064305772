import { LanesAndCapacity } from './CapacityTypes';

export const fakeAPIResponse: LanesAndCapacity[] = [
  {
    carrier: {
      score: 76,
      companyName: 'Triton Logistics Inc.',
      headquartersState: 'Texas',
      headquartersStateAbbr: 'TX',
      trucks: 382,
      isRtsProUser: true,
      mcleodCarrierId: 'TRITBOIL',
      mcNumber: '12345',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 0,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-04T16:16:10.902Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dcae',
        userId: '671a72ca337afa3254a1dcaf',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcb0',
        mcNumber: '123456',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Houston, TX',
        },
        pickupState: '',
        pickupStateAbbr: 'OH',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Virginia',
        deliveryStateAbbr: 'VA',
        trailerType: ['Straight Box Truck'],
        createdAt: '2024-10-15T16:16:10.899Z',
        updatedAt: '2024-10-07T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dcb2',
          trucks: 6,
          loadsPerTimeFrame: 14,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-13T16:16:10.902Z',
          updatedAt: '2024-10-11T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dcb3',
              trailerType: 'Straight Box Truck',
              amount: 2817,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dcb4',
        userId: '671a72ca337afa3254a1dcb5',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcb6',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'North Carolina',
        pickupStateAbbr: 'NC',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'San Francisco, CA',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van', 'Flatbed', 'Flatbed Hotshot'],
        createdAt: '2024-10-15T16:16:10.902Z',
        updatedAt: '2024-10-01T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dcb8',
        userId: '671a72ca337afa3254a1dcb9',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcba',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Illinois',
        pickupStateAbbr: 'IL',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Charlotte, NC',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van', 'Power Only', 'Specialized'],
        createdAt: '2024-10-16T16:16:10.902Z',
        updatedAt: '2024-10-23T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dcbc',
          trucks: 9,
          loadsPerTimeFrame: 9,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-08T16:16:10.902Z',
          updatedAt: '2024-10-19T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dcbd',
              trailerType: 'Van',
              amount: 1536,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dcbe',
        pickupDate: '2024-10-01T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 762,
        customerRate: 3433.694262363338,
      },
      {
        orderId: '671a72ca337afa3254a1dcbf',
        pickupDate: '2024-10-13T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1476,
        customerRate: 4528.279916309439,
      },
      {
        orderId: '671a72ca337afa3254a1dcbf',
        pickupDate: '2024-10-13T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1476,
        customerRate: 4528.279916309439,
      },
      {
        orderId: '671a72ca337afa3254a1dcbf',
        pickupDate: '2024-10-13T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1476,
        customerRate: 4528.279916309439,
      },
      {
        orderId: '671a72ca337afa3254a1dcbf',
        pickupDate: '2024-10-13T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1476,
        customerRate: 4528.279916309439,
      },
    ],
  },
  {
    carrier: {
      score: 80,
      companyName: 'Wolf Pack Trucking',
      headquartersState: 'Texas',
      headquartersStateAbbr: 'KS',
      trucks: 307,
      isRtsProUser: true,
      mcleodCarrierId: 'WOLTKS',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 4,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-16T16:16:10.902Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dcc3',
        userId: '671a72ca337afa3254a1dcc4',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcc5',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Houston, TX',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Boston, NY',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van', 'Straight Box Truck', 'Reefer'],
        createdAt: '2024-10-13T16:16:10.902Z',
        updatedAt: '2024-10-08T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dcc7',
          trucks: 6,
          loadsPerTimeFrame: 16,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-24T16:16:10.902Z',
          updatedAt: '2024-10-01T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dcc8',
              trailerType: 'Van',
              amount: 1453,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dcc9',
        userId: '671a72ca337afa3254a1dcca',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dccb',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'San Diego, CA',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Chicago, IL',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van', 'Flatbed'],
        createdAt: '2024-10-02T16:16:10.902Z',
        updatedAt: '2024-10-03T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dccd',
        userId: '671a72ca337afa3254a1dcce',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dccf',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Illinois',
        pickupStateAbbr: 'IL',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Chicago, IL',
        },
        deliveryState: '',
        deliveryStateAbbr: 'IL',
        trailerType: ['Van'],
        createdAt: '2024-09-28T16:16:10.902Z',
        updatedAt: '2024-10-03T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dcd1',
          trucks: 5,
          loadsPerTimeFrame: 3,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-15T16:16:10.902Z',
          updatedAt: '2024-10-03T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dcd2',
              trailerType: 'Van',
              amount: 3295,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dcd3',
        pickupDate: '2024-09-27T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 450,
        customerRate: 4164.390564932877,
      },
      {
        orderId: '671a72ca337afa3254a1dcd3',
        pickupDate: '2024-09-27T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 450,
        customerRate: 4164.390564932877,
      },
      {
        orderId: '671a72ca337afa3254a1dcd3',
        pickupDate: '2024-09-27T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 450,
        customerRate: 4164.390564932877,
      },
      {
        orderId: '671a72ca337afa3254a1dcd3',
        pickupDate: '2024-09-27T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 450,
        customerRate: 4164.390564932877,
      },
      {
        orderId: '671a72ca337afa3254a1dcd3',
        pickupDate: '2024-09-27T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 450,
        customerRate: 4164.390564932877,
      },
      {
        orderId: '671a72ca337afa3254a1dcd3',
        pickupDate: '2024-09-27T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 450,
        customerRate: 4164.390564932877,
      },
      {
        orderId: '671a72ca337afa3254a1dcd4',
        pickupDate: '2024-10-15T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 348,
        customerRate: 3650.946481916867,
      },
    ],
  },
  {
    carrier: {
      score: 7,
      companyName: "Bob's Trucking Co",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'NE',
      trucks: 302,
      isRtsProUser: false,
      mcleodCarrierId: 'BBTCON',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 8,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-10T16:16:10.902Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dcd8',
        userId: '671a72ca337afa3254a1dcd9',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcda',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Philadelphia, PA',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Virginia',
        deliveryStateAbbr: 'VA',
        trailerType: ['Van'],
        createdAt: '2024-10-18T16:16:10.902Z',
        updatedAt: '2024-10-17T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dcdc',
          trucks: 9,
          loadsPerTimeFrame: 3,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-09-30T16:16:10.902Z',
          updatedAt: '2024-10-11T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dcdd',
              trailerType: 'Van',
              amount: 3922,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dcde',
        userId: '671a72ca337afa3254a1dcdf',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dce0',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Boston, MA',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Detroit, MI',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-09-29T16:16:10.902Z',
        updatedAt: '2024-09-28T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dce2',
        userId: '671a72ca337afa3254a1dce3',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dce4',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Seattle, WA',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Virginia',
        deliveryStateAbbr: 'VA',
        trailerType: ['Van'],
        createdAt: '2024-10-19T16:16:10.902Z',
        updatedAt: '2024-10-13T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dce6',
          trucks: 6,
          loadsPerTimeFrame: 8,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-14T16:16:10.902Z',
          updatedAt: '2024-09-27T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dce7',
              trailerType: 'Van',
              amount: 222,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dce8',
        pickupDate: '2024-10-07T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1957,
        customerRate: 4239.4636454903375,
      },
      {
        orderId: '671a72ca337afa3254a1dce9',
        pickupDate: '2024-10-07T16:16:10.902Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1345,
        customerRate: 2080.0675960626327,
      },
    ],
  },
  {
    carrier: {
      score: 9,
      companyName: 'Swift Carriers',
      headquartersState: 'Texas',
      headquartersStateAbbr: 'KS',
      trucks: 446,
      isRtsProUser: false,
      mcleodCarrierId: 'SWIFTCC',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 3,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-06T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dced',
        userId: '671a72ca337afa3254a1dcee',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcef',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Chicago, IL',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Missouri',
        deliveryStateAbbr: 'MO',
        trailerType: ['Van'],
        createdAt: '2024-10-21T16:16:10.902Z',
        updatedAt: '2024-10-17T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dcf1',
          trucks: 5,
          loadsPerTimeFrame: 7,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-01T16:16:10.902Z',
          updatedAt: '2024-10-02T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dcf2',
              trailerType: 'Van',
              amount: 4085,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dcf3',
        userId: '671a72ca337afa3254a1dcf4',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcf5',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Charlotte, NC',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Minnesota',
        deliveryStateAbbr: 'MN',
        trailerType: ['Van'],
        createdAt: '2024-10-04T16:16:10.902Z',
        updatedAt: '2024-10-09T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dcf7',
        userId: '671a72ca337afa3254a1dcf8',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dcf9',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Charlotte, NC',
        },
        pickupState: '',
        pickupStateAbbr: 'NC',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Massachusetts',
        deliveryStateAbbr: 'MA',
        trailerType: ['Van'],
        createdAt: '2024-09-27T16:16:10.902Z',
        updatedAt: '2024-10-14T16:16:10.902Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dcfb',
          trucks: 2,
          loadsPerTimeFrame: 14,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-20T16:16:10.902Z',
          updatedAt: '2024-10-24T16:16:10.902Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dcfc',
              trailerType: 'Van',
              amount: 74,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfd',
        pickupDate: '2024-10-10T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1373,
        customerRate: 1499.1364562547005,
      },
      {
        orderId: '671a72ca337afa3254a1dcfe',
        pickupDate: '2024-10-13T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1194,
        customerRate: 1551.2244066320002,
      },
    ],
  },
  {
    carrier: {
      score: 44,
      companyName: 'Midwest Haulers',
      headquartersState: 'Texas',
      headquartersStateAbbr: 'OH',
      trucks: 426,
      isRtsProUser: true,
      mcleodCarrierId: 'MDHAUL',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 1,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-09T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd02',
        userId: '671a72ca337afa3254a1dd03',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd04',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Dallas, TX',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Dallas, TX',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-06T16:16:10.903Z',
        updatedAt: '2024-10-06T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd06',
          trucks: 3,
          loadsPerTimeFrame: 6,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-17T16:16:10.903Z',
          updatedAt: '2024-10-12T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd07',
              trailerType: 'Van',
              amount: 1454,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd08',
        userId: '671a72ca337afa3254a1dd09',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd0a',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Michigan',
        pickupStateAbbr: 'MI',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Las Vegas',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-24T16:16:10.903Z',
        updatedAt: '2024-10-13T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd0c',
        userId: '671a72ca337afa3254a1dd0d',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd0e',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'North Carolina',
        pickupStateAbbr: 'NC',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Illinois',
        deliveryStateAbbr: 'IL',
        trailerType: ['Van'],
        createdAt: '2024-10-20T16:16:10.903Z',
        updatedAt: '2024-10-04T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd10',
          trucks: 9,
          loadsPerTimeFrame: 13,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-23T16:16:10.903Z',
          updatedAt: '2024-10-15T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd11',
              trailerType: 'Van',
              amount: 191,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dd12',
        pickupDate: '2024-10-01T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 654,
        customerRate: 2193.2368824143077,
      },
      {
        orderId: '671a72ca337afa3254a1dd13',
        pickupDate: '2024-10-06T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1868,
        customerRate: 4593.227861318771,
      },
    ],
  },
  {
    carrier: {
      score: 59,
      companyName: "Riley's Rippers",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'OH',
      trucks: 457,
      isRtsProUser: false,
      mcleodCarrierId: 'RILRIP',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 4,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-08T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd17',
        userId: '671a72ca337afa3254a1dd18',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd19',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'San Antonio, FL',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Columbus, OH',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-09-29T16:16:10.903Z',
        updatedAt: '2024-10-12T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd1b',
          trucks: 1,
          loadsPerTimeFrame: 10,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-08T16:16:10.903Z',
          updatedAt: '2024-10-06T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd1c',
              trailerType: 'Van',
              amount: 4522,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd1d',
        userId: '671a72ca337afa3254a1dd1e',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd1f',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Indiana',
        pickupStateAbbr: 'IN',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Las Vegas',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-11T16:16:10.903Z',
        updatedAt: '2024-10-11T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd21',
        userId: '671a72ca337afa3254a1dd22',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd23',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Arizona',
        pickupStateAbbr: 'AZ',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Dallas',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-09-29T16:16:10.903Z',
        updatedAt: '2024-10-22T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd25',
          trucks: 1,
          loadsPerTimeFrame: 16,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-14T16:16:10.903Z',
          updatedAt: '2024-10-05T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd26',
              trailerType: 'Van',
              amount: 2140,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dd27',
        pickupDate: '2024-10-19T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1659,
        customerRate: 1944.5499933040112,
      },
      {
        orderId: '671a72ca337afa3254a1dd28',
        pickupDate: '2024-10-24T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1058,
        customerRate: 1465.0063521627055,
      },
    ],
  },
  {
    carrier: {
      score: 67,
      companyName: "Scott's Shipping",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'OH',
      trucks: 216,
      isRtsProUser: false,
      mcleodCarrierId: 'SCOTTSHP',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 8,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-03T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd2c',
        userId: '671a72ca337afa3254a1dd2d',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd2e',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Seattle, WA',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Virginia',
        deliveryStateAbbr: 'VA',
        trailerType: ['Van'],
        createdAt: '2024-10-18T16:16:10.903Z',
        updatedAt: '2024-10-18T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd30',
          trucks: 3,
          loadsPerTimeFrame: 1,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-03T16:16:10.903Z',
          updatedAt: '2024-09-27T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd31',
              trailerType: 'Van',
              amount: 869,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd32',
        userId: '671a72ca337afa3254a1dd33',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd34',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'California',
        pickupStateAbbr: 'CA',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Minnesota',
        deliveryStateAbbr: 'MN',
        trailerType: ['Van'],
        createdAt: '2024-09-26T16:16:10.903Z',
        updatedAt: '2024-10-13T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd36',
        userId: '671a72ca337afa3254a1dd37',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd38',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Illinois',
        pickupStateAbbr: 'IL',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Portland',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-18T16:16:10.903Z',
        updatedAt: '2024-10-21T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd3a',
          trucks: 8,
          loadsPerTimeFrame: 7,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-07T16:16:10.903Z',
          updatedAt: '2024-10-21T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd3b',
              trailerType: 'Van',
              amount: 3232,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dd3c',
        pickupDate: '2024-10-11T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1207,
        customerRate: 499.58701353581716,
      },
      {
        orderId: '671a72ca337afa3254a1dd3c',
        pickupDate: '2024-10-11T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1207,
        customerRate: 499.58701353581716,
      },
      {
        orderId: '671a72ca337afa3254a1dd3c',
        pickupDate: '2024-10-11T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1207,
        customerRate: 499.58701353581716,
      },
      {
        orderId: '671a72ca337afa3254a1dd3c',
        pickupDate: '2024-10-11T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1207,
        customerRate: 499.58701353581716,
      },
      {
        orderId: '671a72ca337afa3254a1dd3d',
        pickupDate: '2024-10-11T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 188,
        customerRate: 4614.035849766862,
      },
    ],
  },
  {
    carrier: {
      score: 71,
      companyName: "Nils' Navigators",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'OH',
      trucks: 285,
      isRtsProUser: true,
      mcleodCarrierId: 'NILNAV',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 2,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-18T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd41',
        userId: '671a72ca337afa3254a1dd42',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd43',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Minnesota',
        pickupStateAbbr: 'MN',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Tennessee',
        deliveryStateAbbr: 'TN',
        trailerType: ['Van'],
        createdAt: '2024-09-25T16:16:10.903Z',
        updatedAt: '2024-10-08T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd45',
          trucks: 7,
          loadsPerTimeFrame: 15,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-24T16:16:10.903Z',
          updatedAt: '2024-10-07T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd46',
              trailerType: 'Van',
              amount: 1639,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd47',
        userId: '671a72ca337afa3254a1dd48',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd49',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'San Diego',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'California',
        deliveryStateAbbr: 'CA',
        trailerType: ['Van'],
        createdAt: '2024-09-29T16:16:10.903Z',
        updatedAt: '2024-09-27T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd4b',
        userId: '671a72ca337afa3254a1dd4c',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd4d',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Boston',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Seattle',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-11T16:16:10.903Z',
        updatedAt: '2024-10-21T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd4f',
          trucks: 7,
          loadsPerTimeFrame: 7,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-09T16:16:10.903Z',
          updatedAt: '2024-10-14T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd50',
              trailerType: 'Van',
              amount: 3605,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dd51',
        pickupDate: '2024-10-09T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1,
        customerRate: 2948.130420766152,
      },
      {
        orderId: '671a72ca337afa3254a1dd52',
        pickupDate: '2024-10-03T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1833,
        customerRate: 4955.17350972239,
      },
    ],
  },
  {
    carrier: {
      score: 44,
      companyName: "Dawit's Deliveries",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'KS',
      trucks: 247,
      isRtsProUser: true,
      mcleodCarrierId: 'DAWDEL',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 5,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-06T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd56',
        userId: '671a72ca337afa3254a1dd57',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd58',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Orlando',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Ohio',
        deliveryStateAbbr: 'OH',
        trailerType: ['Van'],
        createdAt: '2024-10-20T16:16:10.903Z',
        updatedAt: '2024-10-05T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd5a',
          trucks: 8,
          loadsPerTimeFrame: 1,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-18T16:16:10.903Z',
          updatedAt: '2024-10-11T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd5b',
              trailerType: 'Van',
              amount: 3240,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd5c',
        userId: '671a72ca337afa3254a1dd5d',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd5e',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Houston',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Virginia',
        deliveryStateAbbr: 'VA',
        trailerType: ['Van'],
        createdAt: '2024-10-15T16:16:10.903Z',
        updatedAt: '2024-10-09T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd60',
        userId: '671a72ca337afa3254a1dd61',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd62',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'California',
        pickupStateAbbr: 'CA',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Miami',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-09-25T16:16:10.903Z',
        updatedAt: '2024-10-04T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd64',
          trucks: 7,
          loadsPerTimeFrame: 7,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-24T16:16:10.903Z',
          updatedAt: '2024-10-12T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd65',
              trailerType: 'Van',
              amount: 2048,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dd66',
        pickupDate: '2024-10-14T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 520,
        customerRate: 3644.7999951912157,
      },
      {
        orderId: '671a72ca337afa3254a1dd67',
        pickupDate: '2024-10-05T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 301,
        customerRate: 3512.6109210440227,
      },
    ],
  },
  {
    carrier: {
      score: 10,
      companyName: "Austin's Automovers",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'MN',
      trucks: 291,
      isRtsProUser: false,
      mcleodCarrierId: 'AUSTAUT',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 7,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-09-28T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd6b',
        userId: '671a72ca337afa3254a1dd6c',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd6d',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Pennsylvania',
        pickupStateAbbr: 'PA',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Dallas',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-09-25T16:16:10.903Z',
        updatedAt: '2024-10-08T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd6f',
          trucks: 7,
          loadsPerTimeFrame: 13,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-09-29T16:16:10.903Z',
          updatedAt: '2024-10-21T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd70',
              trailerType: 'Van',
              amount: 3997,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd71',
        userId: '671a72ca337afa3254a1dd72',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd73',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Seattle',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Virginia',
        deliveryStateAbbr: 'VA',
        trailerType: ['Van'],
        createdAt: '2024-09-28T16:16:10.903Z',
        updatedAt: '2024-10-23T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd75',
        userId: '671a72ca337afa3254a1dd76',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd77',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Miami',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Florida',
        deliveryStateAbbr: 'FL',
        trailerType: ['Van'],
        createdAt: '2024-10-19T16:16:10.903Z',
        updatedAt: '2024-10-18T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd79',
          trucks: 0,
          loadsPerTimeFrame: 4,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-12T16:16:10.903Z',
          updatedAt: '2024-10-08T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd7a',
              trailerType: 'Van',
              amount: 1830,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dd7b',
        pickupDate: '2024-09-30T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 834,
        customerRate: 848.6751185624797,
      },
      {
        orderId: '671a72ca337afa3254a1dd7c',
        pickupDate: '2024-10-11T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1219,
        customerRate: 3585.031526970044,
      },
    ],
  },
  {
    carrier: {
      score: 87,
      companyName: "Dane's Drivers",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'TX',
      trucks: 83,
      isRtsProUser: false,
      mcleodCarrierId: 'DANEDRV',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 4,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-17T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd80',
        userId: '671a72ca337afa3254a1dd81',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd82',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Wisconsin',
        pickupStateAbbr: 'WI',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Minnesota',
        deliveryStateAbbr: 'MN',
        trailerType: ['Van'],
        createdAt: '2024-10-24T16:16:10.903Z',
        updatedAt: '2024-10-07T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd84',
          trucks: 7,
          loadsPerTimeFrame: 4,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-09-27T16:16:10.903Z',
          updatedAt: '2024-10-11T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd85',
              trailerType: 'Van',
              amount: 4927,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd86',
        userId: '671a72ca337afa3254a1dd87',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd88',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Phoenix',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Houston',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-10T16:16:10.903Z',
        updatedAt: '2024-10-10T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd8a',
        userId: '671a72ca337afa3254a1dd8b',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd8c',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Denver',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'San Antonio',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-07T16:16:10.903Z',
        updatedAt: '2024-10-01T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd8e',
          trucks: 0,
          loadsPerTimeFrame: 0,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-09T16:16:10.903Z',
          updatedAt: '2024-10-23T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd8f',
              trailerType: 'Van',
              amount: 4762,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dd90',
        pickupDate: '2024-09-30T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1020,
        customerRate: 3389.312550557768,
      },
      {
        orderId: '671a72ca337afa3254a1dd91',
        pickupDate: '2024-09-26T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 709,
        customerRate: 2606.1744502669912,
      },
    ],
  },
  {
    carrier: {
      score: 62,
      companyName: "Antonio's Arrivals",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'CH',
      trucks: 86,
      isRtsProUser: true,
      mcleodCarrierId: 'ANJROT',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 7,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-14T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1dd95',
        userId: '671a72ca337afa3254a1dd96',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd97',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'North Carolina',
        pickupStateAbbr: 'NC',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Las Vegas',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-23T16:16:10.903Z',
        updatedAt: '2024-10-06T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dd99',
          trucks: 9,
          loadsPerTimeFrame: 16,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-07T16:16:10.903Z',
          updatedAt: '2024-10-20T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dd9a',
              trailerType: 'Van',
              amount: 346,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1dd9b',
        userId: '671a72ca337afa3254a1dd9c',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dd9d',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'San Diego',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Orlando',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-09-27T16:16:10.903Z',
        updatedAt: '2024-10-21T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1dd9f',
        userId: '671a72ca337afa3254a1dda0',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dda1',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Ohio',
        pickupStateAbbr: 'OH',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Pennsylvania',
        deliveryStateAbbr: 'PA',
        trailerType: ['Van'],
        createdAt: '2024-10-16T16:16:10.903Z',
        updatedAt: '2024-10-08T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dda3',
          trucks: 7,
          loadsPerTimeFrame: 7,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-24T16:16:10.903Z',
          updatedAt: '2024-09-29T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dda4',
              trailerType: 'Van',
              amount: 262,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dda5',
        pickupDate: '2024-10-06T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1061,
        customerRate: 179.1418701611025,
      },
      {
        orderId: '671a72ca337afa3254a1dda6',
        pickupDate: '2024-10-09T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1583,
        customerRate: 132.51442434127458,
      },
    ],
  },
  {
    carrier: {
      score: 74,
      companyName: "Todd's Transports",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'FL',
      trucks: 330,
      isRtsProUser: true,
      mcleodCarrierId: 'TODTRANS',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 8,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-23T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1ddaa',
        userId: '671a72ca337afa3254a1ddab',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1ddac',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Pennsylvania',
        pickupStateAbbr: 'PA',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Philadelphia',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-06T16:16:10.903Z',
        updatedAt: '2024-10-08T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1ddae',
          trucks: 0,
          loadsPerTimeFrame: 11,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-02T16:16:10.903Z',
          updatedAt: '2024-10-10T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1ddaf',
              trailerType: 'Van',
              amount: 1555,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1ddb0',
        userId: '671a72ca337afa3254a1ddb1',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1ddb2',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Boston',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Ohio',
        deliveryStateAbbr: 'OH',
        trailerType: ['Van'],
        createdAt: '2024-10-24T16:16:10.903Z',
        updatedAt: '2024-10-17T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1ddb4',
        userId: '671a72ca337afa3254a1ddb5',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1ddb6',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Chicago',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Orlando',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-12T16:16:10.903Z',
        updatedAt: '2024-10-23T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1ddb8',
          trucks: 8,
          loadsPerTimeFrame: 18,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-02T16:16:10.903Z',
          updatedAt: '2024-10-13T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1ddb9',
              trailerType: 'Van',
              amount: 1145,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1ddba',
        pickupDate: '2024-10-14T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1204,
        customerRate: 4735.711337373705,
      },
      {
        orderId: '671a72ca337afa3254a1ddbb',
        pickupDate: '2024-10-08T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1252,
        customerRate: 2662.6108423148576,
      },
    ],
  },
  {
    carrier: {
      score: 99,
      companyName: "Monica's Movers",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'MO',
      trucks: 180,
      isRtsProUser: false,
      mcleodCarrierId: 'MONMOVS',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 0,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-23T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1ddbf',
        userId: '671a72ca337afa3254a1ddc0',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1ddc1',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Missouri',
        pickupStateAbbr: 'MO',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Portland',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-18T16:16:10.903Z',
        updatedAt: '2024-10-07T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1ddc3',
          trucks: 5,
          loadsPerTimeFrame: 16,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-09-27T16:16:10.903Z',
          updatedAt: '2024-09-27T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1ddc4',
              trailerType: 'Van',
              amount: 2819,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1ddc5',
        userId: '671a72ca337afa3254a1ddc6',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1ddc7',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Tennessee',
        pickupStateAbbr: 'TN',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Tennessee',
        deliveryStateAbbr: 'TN',
        trailerType: ['Van'],
        createdAt: '2024-09-28T16:16:10.903Z',
        updatedAt: '2024-10-03T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1ddc9',
        userId: '671a72ca337afa3254a1ddca',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1ddcb',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Colorado',
        pickupStateAbbr: 'CO',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'North Carolina',
        deliveryStateAbbr: 'NC',
        trailerType: ['Van'],
        createdAt: '2024-09-30T16:16:10.903Z',
        updatedAt: '2024-10-11T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1ddcd',
          trucks: 1,
          loadsPerTimeFrame: 0,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-09-27T16:16:10.903Z',
          updatedAt: '2024-10-08T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1ddce',
              trailerType: 'Van',
              amount: 4155,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1ddcf',
        pickupDate: '2024-09-27T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 603,
        customerRate: 1917.5587642179448,
      },
      {
        orderId: '671a72ca337afa3254a1ddd0',
        pickupDate: '2024-10-12T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 1506,
        customerRate: 3918.1139658905895,
      },
    ],
  },
  {
    carrier: {
      score: 18,
      companyName: "Alicia's Automotion",
      headquartersState: 'Texas',
      headquartersStateAbbr: 'WA',
      trucks: 94,
      isRtsProUser: false,
      mcleodCarrierId: 'ALCAUTM',
      mcNumber: '######',
      dotNumber: '######',
      mcleodStatus: 'Active',
      contactName: 'John Doe',
      contactPhone: '(123) 456-7890',
      contactEmail: 'john.doe@fakeemail.com',
      contactAddress: '1234 Fake Street, Fake City, FA',
      camContactEmail: 'cam@fakecompany.com',
      rtsProAccount: 'rts-pro-123',
      activeUserCount: 2,
      lastUpdatedBy: 'mroesner@ryantrans.com',
      lastUpdatedAt: '2024-10-11T16:16:10.903Z',
    },
    lanes: [
      {
        laneId: '671a72ca337afa3254a1ddd4',
        userId: '671a72ca337afa3254a1ddd5',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1ddd6',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Miami',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Virginia',
        deliveryStateAbbr: 'VA',
        trailerType: ['Van'],
        createdAt: '2024-09-28T16:16:10.903Z',
        updatedAt: '2024-10-14T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1ddd8',
          trucks: 9,
          loadsPerTimeFrame: 19,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-20T16:16:10.903Z',
          updatedAt: '2024-10-01T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1ddd9',
              trailerType: 'Van',
              amount: 4900,
              rateType: 'Flat rate',
            },
          ],
        },
      },
      {
        laneId: '671a72ca337afa3254a1ddda',
        userId: '671a72ca337afa3254a1dddb',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dddc',
        mcNumber: '######',
        pickupType: 'City',
        pickupCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Orlando',
        },
        pickupState: '',
        pickupStateAbbr: '',
        deliveryType: 'City',
        deliveryCity: {
          type: 'string',
          coordinates: [-34234234, 23423423423423],
          cityName: 'Seattle',
        },
        deliveryState: '',
        deliveryStateAbbr: '',
        trailerType: ['Van'],
        createdAt: '2024-10-02T16:16:10.903Z',
        updatedAt: '2024-10-16T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
      },
      {
        laneId: '671a72ca337afa3254a1ddde',
        userId: '671a72ca337afa3254a1dddf',
        email: 'user@fakeemail.com',
        bundleStatus: 'ACTIVE',
        mcLeodCarrierId: '671a72ca337afa3254a1dde0',
        mcNumber: '######',
        pickupType: 'State',
        pickupCity: null,
        pickupState: 'Missouri',
        pickupStateAbbr: 'MO',
        deliveryType: 'State',
        deliveryCity: null,
        deliveryState: 'Missouri',
        deliveryStateAbbr: 'MO',
        trailerType: ['Van'],
        createdAt: '2024-09-29T16:16:10.903Z',
        updatedAt: '2024-10-14T16:16:10.903Z',
        deletedAt: '',
        createdBy: 'admin',
        updatedBy: 'mroesner@ryantrans.com',
        deletedBy: '',
        type: 'Lane',
        capacity: {
          capacityId: '671a72ca337afa3254a1dde2',
          trucks: 8,
          loadsPerTimeFrame: 1,
          frequencyTimeFrame: 'Week',
          loadDetailServices: ['Blanket Wrap'],
          loadDetailNotes: 'Notes about the load',
          createdAt: '2024-10-02T16:16:10.903Z',
          updatedAt: '2024-10-10T16:16:10.903Z',
          deletedAt: '',
          createdBy: 'admin',
          updatedBy: 'mroesner@ryantrans.com',
          deletedBy: '',
          rate: [
            {
              rateId: '671a72ca337afa3254a1dde3',
              trailerType: 'Van',
              amount: 3509,
              rateType: 'Flat rate',
            },
          ],
        },
      },
    ],
    loadHistory: [
      {
        orderId: '671a72ca337afa3254a1dde4',
        pickupDate: '2024-10-03T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 384,
        customerRate: 1857.655407942993,
      },
      {
        orderId: '671a72ca337afa3254a1dde5',
        pickupDate: '2024-10-22T16:16:10.903Z',
        customer: 'Fake Company',
        equipment: 'Van',
        originCity: 'Los Angeles',
        originStateAbbr: 'CA',
        destinationCity: 'Miami',
        destinationStateAbbr: 'FL',
        miles: 760,
        customerRate: 4723.050303987966,
      },
    ],
  },
];
