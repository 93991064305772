import { Button, Icon, styled } from 'shamrock-clover-ui';
import {
  Card,
  CardContent,
  CardHeaderTitles,
  CardsCapacityContainer,
  CustomButton,
  CardHeader,
} from './DetailsTableStyles';
import EditIcon from 'components/Icon';
import { FC } from 'react';
import { DetailsTableProps } from 'features/carrierCapacity/CapacityTypes';
import { capitalizeWords } from 'features/carrierCapacity/utils/capacityUtils';
import { formatDate } from 'utils/dateUtils';

const columnsHeader = [
  'Pickup',
  'Delivery',
  'Equipment',
  'Capacity',
  'History',
  'Edit',
];

const EditPencilIcon = styled(EditIcon)`
  cursor: pointer;
`;

const DetailsTable: FC<DetailsTableProps> = ({
  data,
  actionHandler,
  editHandler,
}) => {
  const { lanes } = data;
  return (
    <div style={{ padding: '20px 0px 16px 0px' }}>
      <CardsCapacityContainer>
        <CardHeader>
          <div>
            <p>
              Lanes: <span>{lanes.length}</span>
            </p>
          </div>
          <div>{/*Here the Sort Component*/}</div>
          <div>
            <CustomButton
              buttonStyle="solid"
              color="blue"
              icon={<Icon icon="plus" color="white" size="16" />}
              onClick={actionHandler}
            >
              Add Lane
            </CustomButton>
          </div>
        </CardHeader>
        <CardHeaderTitles>
          {columnsHeader.map((x) => (
            <span>{x}</span>
          ))}
        </CardHeaderTitles>
        {lanes.map((x, index) => (
          <Card>
            <CardContent>
              <span style={{ fontWeight: 600 }}>
                {x.pickupCity?.cityName || x.pickupState}
              </span>
              <Icon icon="arrowRight" size="16" color="gray4" />
            </CardContent>
            <CardContent>
              <span style={{ fontWeight: 600 }}>
                {x.deliveryCity?.cityName || x.deliveryState}
              </span>
            </CardContent>
            <CardContent>
              <span style={{ fontWeight: 600 }}>
                {x.trailerType.join(', ')}
              </span>
            </CardContent>
            {x?.capacity ? (
              <CardContent style={{ display: 'block' }}>
                <span style={{ fontWeight: 500 }}>
                  {`${x.capacity.trucks} trucks, ${x.capacity.loadsPerTimeFrame}/${x.capacity.frequencyTimeFrame.toLowerCase()}, $${x.capacity.rate[0].amount.toFixed(2)} ${x.capacity.rate[0].rateType.toLowerCase()}, ${capitalizeWords(x.capacity.loadDetailServices.join(', '))}`}
                </span>
                <br />
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#333333',
                  }}
                >
                  Notes: {x.capacity.loadDetailNotes}
                </span>
              </CardContent>
            ) : (
              <CardContent>
                <Button
                  icon={<Icon icon="plus" color="blue" size="14" />}
                  buttonStyle="text"
                >
                  Add Capacity
                </Button>
              </CardContent>
            )}

            <CardContent
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <span style={{ fontWeight: 600 }}>{x.type} </span>
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#333333',
                }}
              >
                {x.email}
              </span>
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#333333',
                }}
              >
                {formatDate(x.createdAt)}
              </span>
            </CardContent>
            <CardContent>
              <EditPencilIcon
                name="EditPencil"
                onHoverName="EditPencilHover"
                alt="EditIcon"
                onClick={editHandler ? () => editHandler(index) : undefined}
              />
            </CardContent>
          </Card>
        ))}
      </CardsCapacityContainer>
    </div>
  );
};

export default DetailsTable;
