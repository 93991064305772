import { useState } from 'react';
import { FormControl, Select, MenuItem, withStyles } from '@material-ui/core';
import { Flex } from '../../../components/Flex';
import { useRateProQueryParams } from '../../../hooks/useQueryParams';
import { LabelText } from 'features/ratePro/rateProStyles';

const trailerTypes = [
  'Van',
  'Straight Box Truck',
  'Reefer',
  'Flatbed',
  'Flatbed Hotshot',
  'Step Deck',
  'Power Only',
  'Specialized',
];

const StyledFormControl = withStyles({
  root: {
    width: '126px',
    height: '36px',
    background: 'white',
    borderRadius: '20px',
    border: 'none',
    '&.needs-border': {
      border: '1px solid red',
    },
  },
})(FormControl);

const StyledMenuItem = withStyles({
  root: {
    fontSize: '14px',
  },
})(MenuItem);

const StyledSelect = withStyles({
  root: {
    fontSize: '14px',
  },
  select: {
    fontSize: '14px',
    paddingLeft: '15px',
    paddingTop: '10px',
    fontFamily: 'Proxima Nova',
  },
})(Select);

const EquipmentPicker = () => {
  const { addQueryParams, queryParams } = useRateProQueryParams();
  const [needsToSelectTrailer, setNeedsToSelectTrailer] = useState(false);

  const selectedTrailer = queryParams.trailerType
    ? (queryParams.trailerType as string)
    : 'placeholder';

  const onChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: any }>,
  ) => {
    addQueryParams({ trailerType: event.target.value });
    setNeedsToSelectTrailer(false);
  };

  const onBlur = () => {
    if (selectedTrailer === 'placeholder') {
      setNeedsToSelectTrailer(true);
    }
  };

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>Equipment</LabelText>
      <StyledFormControl className={needsToSelectTrailer ? 'needs-border' : ''}>
        <StyledSelect
          MenuProps={{ variant: 'menu' }}
          disableUnderline
          value={selectedTrailer}
          onChange={onChange}
          onClose={onBlur}
        >
          <StyledMenuItem disabled value="placeholder">
            Trailer Type
          </StyledMenuItem>
          {trailerTypes.map((trailer) => (
            <StyledMenuItem key={trailer} value={trailer}>
              {trailer}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Flex>
  );
};

export default EquipmentPicker;
